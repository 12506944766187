import React from "react"

import Layout from "../layouts/Base/layout"
import SEO from "../components/atoms/SEO/SEO"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <h1>Página não construída</h1>
    <Link to="/">Ir para a página principal</Link>
  </Layout>
)

export default NotFoundPage
